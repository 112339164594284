const GetLocale = (language) => {
    switch (language) {
        case 'fi':
            return 'fi-FI';
        case 'sv':
            return 'sv-SE';
        default:
            return 'en-GB';
    }
}

const FormatTime = (time, language) => {
    const locale = GetLocale(language);
    const date = new Date(time);

    let options = {
        dateStyle: 'short',
        timeStyle: 'short',
        hourCycle: 'h24'
    };

    if (locale === 'en-GB') {
        options.dateStyle = 'medium';
    }

    return date.toLocaleString(locale, options);
}

export default FormatTime;