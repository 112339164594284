//export const BASE_API_URL = 'http://localhost:8000/api/';
export const BASE_API_URL = 'https://innext.utu.fi/api/';

//export const PUBLIC_URL = 'http://localhost:3000/api/';
export const PUBLIC_URL = 'https://innext.utu.fi/';

export const DEFAULT_LANGUAGE = 'en';

// The same (in days) than in SIMPLE_JWT django settings
export const ACCESS_TOKEN_LIFETIME = 1;

export const LOGO = PUBLIC_URL + 'assets/images/innext-logo.png';
export const LOGO_FOOTER_EN = PUBLIC_URL + 'assets/images/footer-logot-EN.png';
export const LOGO_FOOTER_FI = PUBLIC_URL + 'assets/images/footer-logot-FI.png';

export const NO_IMAGE = PUBLIC_URL + 'assets/images/noimg.png';