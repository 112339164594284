import { Alert, AlertTitle, Box } from '@mui/material';


const AlertMessage = ({ title, message, type }) => {
    return (
        <Box sx={{ mb: '2rem' }}>
            <Alert severity={type}>
                {title && <AlertTitle>{title}</AlertTitle>}
                {message}
            </Alert>
        </Box>
    );
}

export default AlertMessage;