import useAuth from './useAuth';
import { axiosPrivateInstance } from '../utils/apiConfig';


const useLogout = () => {
    const { setUser, setAccessToken, setCSRFToken, setIsLoggedIn } = useAuth();

    const logout = async () => {
        try {
            await axiosPrivateInstance.post('auth/logout/');

            setAccessToken(null);
            setCSRFToken(null);
            setUser({});
            setIsLoggedIn(false);

        } catch (error) {
            console.log(error);
        }
    }

    return logout;
}

export default useLogout;