import { useTranslation } from 'react-i18next';

import { Box, Card, CardContent, Link, Typography } from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';
import MailOutlineIcon from '@mui/icons-material/MailOutline';

import { useLanguage } from '../../context/LanguageContext';
import ExpandContent from './ExpandContent';


const ContactPersons = ({ persons }) => {
    const { t } = useTranslation();
    const { language } = useLanguage();
    const defaultLanguage = 'en';

    const contactPersons = (
        <Box display='flex' flexWrap='wrap' alignItems='flex-start' columnGap='20px' sx={{ mx: '1.75rem' }}>
            {persons &&
                persons.map((person) => {
                    const title = person.title[language] ? person.title[language] : person.title[defaultLanguage];

                    return (
                        <Box key={person.id}>
                            <Card elevation={0} sx={{ px: 0 }}>
                                <CardContent sx={{ pb: '0.5rem' }}>
                                    <Typography component='h5' variant='h5' sx={{ mb: '0.25rem', fontSize: '1.1rem' }}>
                                        {person.name}
                                    </Typography>
                                    <Typography variant='body2' fontSize='1rem'>
                                        {title}
                                        <br />
                                        {person.organization}
                                        <br />
                                        <PhoneIcon sx={{ width: '1rem', height: '1rem', mr: '0.5rem' }} />
                                        <Link href={'tel:' + person.phone}>
                                            {person.phone}
                                        </Link>
                                        <br />
                                        <MailOutlineIcon sx={{ width: '1rem', height: '1rem', mr: '0.5rem' }} />
                                        <Link href={'mailto:' + person.email}>
                                            {person.email}
                                        </Link>
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Box>
                    );
                })}
        </Box>
    );

    return (
        <>
            <ExpandContent buttonText={t('panel.titleContact')} collapseContent={contactPersons} />
        </>
    );
}

export default ContactPersons;