import { useTranslation } from 'react-i18next';

import { Box, Card, CardActionArea, CardContent, CardMedia, Icon, Link, Stack, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';

import { DEFAULT_LANGUAGE, NO_IMAGE } from '../constants';
import { useLanguage } from '../context/LanguageContext';
import { getClosureTime } from '../actions';
import useAuth from '../hooks/useAuth';
import FormatTime from '../utils/formatTime';


const PanelList = ({ panels }) => {
    const { t } = useTranslation();
    const { language } = useLanguage();
    const { isLoggedIn } = useAuth();

    let { image, url, title } = '';

    const cardImage = (image, url) => {
        const imageStyles = {
            display: 'block',
            width: '220px',
            height: '100%',
            bgcolor: 'investDarkGrey',
            backgroundImage: `url(${image})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center'
        };

        return (
            isLoggedIn ?
                <CardActionArea
                    component={Link}
                    href={url}
                    sx={imageStyles}
                />
                :
                <CardMedia
                    component='div'
                    sx={imageStyles}
                />
        )
    };

    const cardTitle = (url, title) => (
        isLoggedIn ?
            <CardActionArea component={Link} href={url}>
                <Typography gutterBottom variant='h3' sx={{ mb: '1rem' }}>
                    {title}
                </Typography>
            </CardActionArea>
            :
            <Typography gutterBottom variant='h3' sx={{ mb: '1rem' }}>
                {title}
            </Typography>
    );

    const listPanels = (panels) => {
        const panelCards = panels.map((panel) => {
            image = panel.image ? panel.image : NO_IMAGE;
            title = panel.title[language] ? panel.title[language] : panel.title[DEFAULT_LANGUAGE];
            url = '../panel/' + panel.slug;
            let closed = getClosureTime(panel.close_at);

            return (
                <Card square elevation={2} sx={{ display: 'flex', width: '100%', bgcolor: 'white' }} key={panel.id}>
                    <Box sx={{ flexBasis: '220px' }}>
                        {cardImage(image, url)}
                    </Box>
                    <Box sx={{ flexGrow: 2 }}>
                        <CardContent sx={{ p: '1.5rem' }}>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                {cardTitle(url, title)}
                                <Box sx={{ flexBasis: '150px' }}>
                                    <Typography sx={{ textAlign: 'right' }}>
                                        <Icon
                                            className='far fa-comment'
                                            sx={{ mr: '0.25rem', verticalAlign: 'bottom' }}
                                        />
                                        {panel.n_comments}
                                    </Typography>
                                </Box>
                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>
                                <Typography component='div' sx={{ fontSize: '0.9rem' }}>
                                    {t('panel.open')} {FormatTime(panel.open_at, language)}<br />
                                    {closed ? t('panel.closed') : t('panel.closing')} {FormatTime(panel.close_at, language)}
                                </Typography>
                                {closed && (
                                    <Typography
                                        component='div'
                                        sx={{
                                            p: '0.1rem 0.5rem',
                                            bgcolor: 'investBlue',
                                            color: 'white',
                                            fontSize: '0.7rem',
                                            fontWeight: 'bold',
                                            textTransform: 'uppercase'
                                        }}
                                    >
                                        {t('panel.hasClosed')}
                                    </Typography>
                                )}
                            </Box>
                        </CardContent>
                    </Box>
                </Card>
            );
        });
        return panelCards;
    }

    return (
        <>
            <Grid container spacing={2}>
                <Grid size={12}>
                    <Stack direction='row' spacing={1} className='panel-list__header'
                        sx={{ justifyContent: 'space-between', mb: '1rem' }}
                    >
                        {panels && (
                            <Box className='panel-list__list-title' sx={{ fontWeight: '500' }}>
                                {Object.keys(panels).length} {t('panel.list.panels')}
                            </Box>
                        )}
                        <Box className='panel-list__list-sorting ms-auto'>
                            {/*t('panel.list.sort')*/}
                        </Box>
                    </Stack>
                </Grid>
                {panels &&
                    listPanels(panels)}
            </Grid>
        </>
    );
}

export default PanelList;