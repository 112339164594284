import { useState, useEffect, createContext } from 'react';

import { getCookieWithExpiry, setCookieWithExpiry } from '../actions';


export const AuthContext = createContext({
    user: {},
    setUser: () => { },
    accessToken: null,
    refreshToken: null,
    csrftoken: null,
    setAccessToken: () => { },
    setRefreshToken: () => { },
    setCSRFToken: () => { },
    isLoggedIn: false,
    setIsLoggedIn: () => { },
});

export function AuthContextProvider(props) {
    const [user, setUser] = useState({});
    const [accessToken, setAccessToken] = useState();
    const [refreshToken, setRefreshToken] = useState();
    const [csrftoken, setCSRFToken] = useState();
    //const [isLoggedIn, setIsLoggedIn] = useState(JSON.parse(localStorage.getItem('isLoggedIn')) || false);
    const [isLoggedIn, setIsLoggedIn] = useState(getCookieWithExpiry('isLoggedIn') || false);

    useEffect(() => {
        //localStorage.setItem('isLoggedIn', JSON.stringify(isLoggedIn));
        setCookieWithExpiry('isLoggedIn', isLoggedIn);
    }, [isLoggedIn]);

    return (
        <AuthContext.Provider value={{
            user, setUser,
            accessToken, setAccessToken,
            refreshToken, setRefreshToken,
            csrftoken, setCSRFToken,
            isLoggedIn, setIsLoggedIn
        }}>
            {props.children}
        </AuthContext.Provider>
    );
}

export default AuthContext;