import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Button, TextField } from '@mui/material';
import Grid from '@mui/material/Grid2';

import { axiosInstanceNoCredentials } from '../../utils/apiConfig';

import Hero from '../../components/layout/Hero';
import Main from '../../components/layout/Main';
import AlertMessage from '../../components/forms/AlertMessage';
import { passwordValidator } from '../../components/forms/validators';


const ResetPassword = () => {
    const { t } = useTranslation();

    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState(null);
    const [alertType, setAlertType] = useState('warning');
    const [formValues, setFormValues] = useState({
        password: {
            value: '',
            validator: passwordValidator,
            error: false
        },
        password2: {
            value: '',
            error: false
        },
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        const validator = formValues[name].validator;
        let error = '';

        if (name === 'password2') {
            if (value !== formValues.password.value) {
                error = "Passwords do not match";
            } else {
                error = false;
            }
        } else {
            error = validator(value);
        }

        setFormValues({
            ...formValues,
            [name]: {
                ...formValues[name],
                value,
                error
            }
        });
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (e.currentTarget.checkValidity() === false) {
            setMessage(t('form.error.form'));
            setAlertType('error');
        } else {
            setLoading(true);

            let password = formValues.password.value;
            let password2 = formValues.password2.value;

            try {
                const response = await axiosInstanceNoCredentials.post('auth/password-reset/', JSON.stringify({
                    password,
                    password2
                }));
                setLoading(false);
                setMessage('Password reset succeeded. You can log in now.');
                setAlertType('success');
            } catch (error) {
                setLoading(false);
                // TODO: handle errors
                setMessage(error.response.data.detail);
                //setMessage('Error sending password reset email.');
                setAlertType('error');
            }
        }
    }

    const heroTitle = t('form.newPasswordTitle');
    const heroContent = '';

    const mainContent = (
        <Grid container spacing={2}>
            <Grid size={{ xs: 12 }}>
                {message && <AlertMessage message={message} type={alertType} />}
            </Grid>
            <Grid size={{ xs: 12, sm: 10, md: 8 }} offset={{ xs: 0, sm: 1, md: 2 }}>
                <Box
                    component='form'
                    onSubmit={handleSubmit}
                    onChange={handleChange}
                    noValidate
                >
                    <TextField
                        name='password'
                        type='password'
                        label={t('form.password')}
                        value={formValues.password.value}
                        onChange={handleChange}
                        error={!!formValues.password.error}
                        helperText={formValues.password.error}
                        required
                        fullWidth
                        margin='dense'
                    />
                    <TextField
                        name='password2'
                        type='password'
                        label={t('form.passwordConfirm')}
                        value={formValues.password2.value}
                        onChange={handleChange}
                        error={!!formValues.password2.error}
                        helperText={formValues.password2.error}
                        required
                        fullWidth
                        margin='dense'
                    />
                    <Button
                        type='submit'
                        variant='contained'
                        disabled={loading}
                        fullWidth
                        sx={{ mt: '0.5rem' }}
                    >
                        {t('form.buttonSubmit')}
                    </Button>
                </Box>
            </Grid>
        </Grid>
    );

    return (
        <>
            <Hero title={heroTitle} content={heroContent} />
            <Main width='md' content={mainContent} />
        </>
    );
}

export default ResetPassword;