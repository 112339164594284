import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Link, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';

import useAuth from '../../hooks/useAuth';
import useUser from '../../hooks/useUser';

import Hero from '../../components/layout/Hero';
import Main from '../../components/layout/Main';


const UserProfile = () => {
    const { t } = useTranslation();

    const { user } = useAuth();
    /*const getUser = useUser();

    useEffect(() => {
        getUser();
    }, []);*/

    const heroTitle = t('user.title');
    const heroContent = '';

    const mainContent = (
        <Grid container spacing={2}>
            <Grid size={12}>
                <Typography variant='h2'>
                    {t('user.hello') + "!"}
                </Typography>
            </Grid>
            <Grid size={12}>
                <Typography>
                    Your username: <strong>{user?.username}</strong><br />
                    Your email: <strong>{user?.email}</strong>
                </Typography>
                {/*<Box sx={{ mt: '2rem' }}>
                    FORM: {t('user.changePassword')}
                </Box>*/}
            </Grid>
        </Grid>
    );

    return (
        <>
            <Hero title={heroTitle} content={heroContent} />
            <Main width='md' content={mainContent} />
        </>
    );
}

export default UserProfile;