import { PUBLIC_URL, ACCESS_TOKEN_LIFETIME } from '../constants';
import { axiosInstanceNoCredentials } from '../utils/apiConfig';


export const fetchData = async (endpoint) => {
    try {
        const response = await axiosInstanceNoCredentials.get(endpoint);
        return response.data;
    } catch (error) {
        console.error("FETCH ERROR!!!", error);
        throw error;
    }
};

export const getPanelURL = (panelSlug) => {
    const url = PUBLIC_URL + 'panel/' + panelSlug + '/';
    return url;
}

export const getSectionURL = (panelSlug, sectionId) => {
    const url = PUBLIC_URL + 'panel/' + panelSlug + '/' + sectionId + '/';
    return url;
}

export const getClosureTime = (time) => {
    return new Date(time).getTime() < new Date().getTime();
}

export const getCookieWithExpiry = (key) => {
    const itemStr = localStorage.getItem(key);

    // if the item doesn't exist, return null
    if (!itemStr) {
        return null;
    }

    const item = JSON.parse(itemStr);
    const now = new Date().getTime();
    
    // compare the expiry time of the item with the current time
    // delete the item from storage if the item is expired
    if (now > item.expiry) {
        localStorage.removeItem(key);
        return null;
    }
    return item.value;
}

export const setCookieWithExpiry = (key, value) => {
    const now = new Date().getTime();
    // reset when storage is more than X days
    const ttl = (24 * 60 * 60 * 1000) * ACCESS_TOKEN_LIFETIME;
    //const ttl = 10000;

    // `item` is an object which contains the original value as well as the time when it's supposed to expire
    const item = {
        value: value,
        expiry: now + ttl,
    }
    localStorage.setItem(key, JSON.stringify(item));
}
