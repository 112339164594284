import { createTheme } from '@mui/material/styles';
import { grey } from '@mui/material/colors';


const investBlue = '#003f5a';
const investTurq = '#009bb0';
const investGreen = '#76c04d';
const investLightGrey = '#f2f2f2';
const investDarkGrey = '#333';

const fontSansSerif = 'Open Sans, Roboto, Helvetica, Arial, sans-serif';
const fontSerif = 'Bitter, Georgia, Times New Roman, serif';


const theme = createTheme({
    palette: {
        primary: {
            // light: will be calculated from palette.primary.main,
            main: investBlue,
            dark: investDarkGrey,
            contrastText: 'white',
        },
        secondary: {
            light: grey[100],
            main: '#fff',
            // dark: will be calculated from palette.secondary.main,
            contrastText: '#ffcc00',
        },
        background: {
            default: 'black',
        },
        investBlue,
        investTurq,
        investGreen,
        investLightGrey,
        investDarkGrey,
    },
    typography: {
        fontFamily: fontSansSerif,
        fontSize: 16,
    },
    components: {
        MuiAppBar: {
            styleOverrides: {
                colorPrimary: {
                    backgroundColor: 'white',
                    color: 'black',
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: 'none',
                },
                focusHighlight: {
                    color: 'transparent',
                }
            },
        },
        MuiButtonBase: {
            styleOverrides: {
                focusHighlight: {
                    color: 'transparent',
                }
            }
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    bgcolor: grey[100],
                }
            }
        },
        MuiCardActionArea: {
            styleOverrides: {
                focusHighlight: {
                    color: 'transparent',
                }
            }
        },
        MuiCardContent: {
            styleOverrides: {
                root: {
                    '&:last-child': {
                        paddingBottom: '16px'
                    }
                }
            }
        },
        MuiIcon: {
            styleOverrides: {
                root: {
                    // Match 24px = 3 * 2 + 1.125 * 16
                    boxSizing: 'content-box',
                    padding: 3,
                    fontSize: '1.125rem',
                },
            },
        },
        MuiTypography: {
            styleOverrides: {
                root: {
                    fontSize: '1rem',
                },
                h1: {
                    marginBottom: '1.5rem',
                    fontSize: '2.5rem',
                    fontWeight: 700,
                },
                h2: {
                    marginBottom: '1.5rem',
                    fontFamily: fontSerif,
                    fontSize: '2rem',
                    fontWeight: 700,
                },
                h3: {
                    marginBottom: '1.5rem',
                    fontSize: '1.75rem',
                    fontWeight: 700,
                },
                h4: {
                    marginBottom: '1.5rem',
                    fontSize: '1.5rem',
                    fontWeight: 400,
                },
                h5: {
                    marginBottom: '1.5rem',
                    fontSize: '1rem',
                    fontWeight: 700,
                },
                p: {
                    fontSize: '1rem',
                },
                herotext: {
                    display: 'block',
                    fontSize: '1.4rem',
                },
            }
        },
    },
});

export default theme;