import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import { DEFAULT_LANGUAGE } from './constants';

// Importing translation files
import translationsEN from './locales/en.json';
import translationsFI from './locales/fi.json';
import translationsSV from './locales/sv.json';


//i18N Initialization
i18n
    .use(initReactI18next)
    .init({
        resources: {
            en: { translation: translationsEN },
            fi: { translation: translationsFI },
            sv: { translation: translationsSV }
        },
        lng: localStorage.getItem('language') ?? 'en', // default language
        fallbackLng: DEFAULT_LANGUAGE,
        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;


/*export const getCurrentLanguage = () => {
    return i18n.language;
};


export const changeLanguage = (language) => {
    i18n.changeLanguage(language);
};*/
