import { useTranslation } from 'react-i18next';

import { Card, CardActionArea, CardContent, CardMedia, Icon, Link, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { grey } from '@mui/material/colors';

import { DEFAULT_LANGUAGE, NO_IMAGE } from '../constants';
import { useLanguage } from '../context/LanguageContext';
import useAuth from '../hooks/useAuth';


const PanelCard = ({ panels }) => {
    const { t } = useTranslation();
    const { language } = useLanguage();
    const { isLoggedIn } = useAuth();

    let { image, url, title } = '';

    const cardImage = (image, url, title) => (
        isLoggedIn ?
            <CardActionArea component={Link} href={url}>
                <CardMedia
                    component='img'
                    image={image}
                    alt={title}
                    sx={{ height: '220px' }}
                />
            </CardActionArea>
            :
            <CardMedia
                component='img'
                image={image}
                alt={title}
                sx={{ height: '220px' }}
            />
    );

    const cardTitle = (url, title) => (
        isLoggedIn ?
            <CardActionArea component={Link} href={url}>
                <Typography gutterBottom variant='h3' sx={{ marginBottom: '1rem' }}>
                    {title}
                </Typography>
            </CardActionArea>
            :
            <Typography gutterBottom variant='h3' sx={{ marginBottom: '1rem' }}>
                {title}
            </Typography>
    );

    return (
        <>
            {panels && panels.map((panel) => {
                image = panel.image ? panel.image : NO_IMAGE;
                url = 'panel/' + panel.slug;
                title = panel.title[language] ? panel.title[language] : panel.title[DEFAULT_LANGUAGE];

                const dateOptions = {
                    month: '2-digit',
                    day: '2-digit',
                    hour: 'numeric',
                    minute: 'numeric'
                }
                const openDate = new Date(panel.open_at).toLocaleString(language, dateOptions)
                //const openDate = new Intl.DateTimeFormat(language, dateOptions).format(panel.open_at);
                const closeDate = new Date(panel.close_at).toLocaleString(language, Object.assign({}, dateOptions, { year: 'numeric' }))

                return (
                    <Grid size={{ xs: 12, md: 6, lg: 4 }} key={panel.id} sx={{ alignSelf: 'stretch' }}>
                        <Card square elevation={0} sx={{ maxWidth: '100%', height: '100%', bgcolor: grey[100] }}>
                            {cardImage(image, url, title)}
                            <CardContent sx={{ padding: '1.5rem' }}>
                                {cardTitle(url, title)}
                                <Typography component='div' sx={{ fontSize: '0.9rem' }}>
                                    <Icon
                                        className='fas fa-calendar-days'
                                        sx={{ mr: '0.25rem', verticalAlign: 'bottom' }}
                                    />
                                    {openDate} - {closeDate}
                                </Typography>
                            </CardContent>

                        </Card>
                    </Grid>
                );
            })}
        </>
    );
}

export default PanelCard;