// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*html {
    height: 100%;
    -webkit-text-size-adjust: 100%;
}
body {
    display: block;
    height: 100%;
    background-color: #eee;
}
#root {
    display: block;
    height: 100%;

}*/

body {
    color: #333;
}

a {
    color: #000;
}
a:hover {
    color: #009bb0;
    text-decoration: none;
}

/***** MAIN *****/


/**** FOOTER *****/

footer {
    color: #fff;
}

footer a {
    color: #fff;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;;;;;;;;;;;;;EAaE;;AAEF;IACI,WAAW;AACf;;AAEA;IACI,WAAW;AACf;AACA;IACI,cAAc;IACd,qBAAqB;AACzB;;AAEA,iBAAiB;;;AAGjB,kBAAkB;;AAElB;IACI,WAAW;AACf;;AAEA;IACI,WAAW;AACf","sourcesContent":["/*html {\n    height: 100%;\n    -webkit-text-size-adjust: 100%;\n}\nbody {\n    display: block;\n    height: 100%;\n    background-color: #eee;\n}\n#root {\n    display: block;\n    height: 100%;\n\n}*/\n\nbody {\n    color: #333;\n}\n\na {\n    color: #000;\n}\na:hover {\n    color: #009bb0;\n    text-decoration: none;\n}\n\n/***** MAIN *****/\n\n\n/**** FOOTER *****/\n\nfooter {\n    color: #fff;\n}\n\nfooter a {\n    color: #fff;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
