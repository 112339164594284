import { Routes, Route } from 'react-router-dom';

// Pages
import Home from './pages/Home';
import Page from './pages/Page';
import Panel from './pages/Panel';
import Panels from './pages/Panels';
import Section from './pages/Section';

// Authentication
import PersistLogin from './actions/PersistLogin';
import AuthMiddleware from './middlewares/AuthMiddleware';
import Login from './pages/auth/Login';
import Register from './pages/auth/Register';
import ResendActivation from './pages/auth/ResendActivation';
import RequestResetPassword from './pages/auth/RequestResetPassword';
import ResetPassword from './pages/auth/ResetPassword';
import ActivateUser from './pages/auth/ActivateUser';
import UserProfile from './pages/auth/UserProfile';


export const AppRoutes = () => {

    return (
        <Routes>
            <Route path='/' element={<PersistLogin />}>
                <Route index element={<Home />} />
                <Route path='info' element={<Page slug='info' />} />
                <Route path='privacy-policy' element={<Page slug='privacy-policy' />} />
                <Route path='accessibility' element={<Page slug='accessibility' />} />
                <Route path='panels' element={<Panels />} />
                <Route path='panel' element={<AuthMiddleware />}>
                    <Route path=':slug' element={<Panel />} />
                    <Route path=':slug/:sectionId' element={<Section />} />
                </Route>
                <Route path='auth'>
                    <Route path='login' element={<Login />} />
                    <Route path='register' element={<Register />} />
                    <Route path='activate/:token' element={<ActivateUser />} />
                    <Route path='resend-activation' element={<ResendActivation />} />
                    <Route path='reset-password' element={<RequestResetPassword />} />
                    <Route path='reset-password/:ref' element={<ResetPassword />} />
                    <Route index element={<Login />} />
                </Route>
                <Route path='user' element={<AuthMiddleware />}>
                    <Route index element={<UserProfile />} />
                </Route>
            </Route>
        </Routes>
    );
}

export default AppRoutes;