import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Box, Divider, Icon, ImageListItem, ImageListItemBar, Link, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';

import { fetchData, getPanelURL, getClosureTime } from '../actions';
import { DEFAULT_LANGUAGE } from '../constants';
import { useLanguage } from '../context/LanguageContext';

import SectionComments from '../components/SectionComments';
import AlertMessage from '../components/forms/AlertMessage';
import Hero from '../components/layout/Hero';
import Main from '../components/layout/Main';


const Section = () => {
    const { t } = useTranslation();
    const { language } = useLanguage();
    const { slug, sectionId } = useParams();
    
    const panelApiUrl = 'panel/' + slug;
    const sectionApiUrl = panelApiUrl + '/sections/' + sectionId;
    const [panelData, setPanelData] = useState(null);
    const [sectionData, setSectionData] = useState(null);
    const mainPanelUrl = getPanelURL(slug);

    useEffect(() => {
        fetchData(panelApiUrl)
            .then(data => setPanelData(data))
            .catch(() => { })
        fetchData(sectionApiUrl)
            .then(data => setSectionData(data))
            .catch(() => { })
    }, [panelApiUrl, sectionApiUrl]);

    let { closed, commenting, image, title, abstract, content, caption, alt_text } = '';
    let comment_amount = 0;

    if (panelData !== null) {
        closed = getClosureTime(panelData.close_at);
    }

    if (sectionData !== null) {
        if (sectionData.n_comments > 0)
            comment_amount = sectionData.n_comments;

        commenting = sectionData.commenting;
        image = sectionData.image;

        title = sectionData.title[language] ? sectionData.title[language] : sectionData.title[DEFAULT_LANGUAGE];
        abstract = sectionData.abstract[language] ? sectionData.abstract[language] : sectionData.abstract[DEFAULT_LANGUAGE];
        content = sectionData.content[language] ? sectionData.content[language] : sectionData.content[DEFAULT_LANGUAGE];
        alt_text = sectionData.alt_text[language] ? sectionData.alt_text[language] : sectionData.alt_text[DEFAULT_LANGUAGE];
        caption = sectionData.caption[language] ? sectionData.caption[language] : sectionData.caption[DEFAULT_LANGUAGE];
    }

    const heroTitle = title;
    const heroContent = (
        <Grid container spacing={2}>
            <Grid size={{ xs: 12 }}>
                <Divider variant='middle' sx={{ my: '1rem', bgcolor: 'investTurq' }} />
            </Grid>
            <Grid size={{ xs: 12 }} sx={{ px: '1rem', fontSize: '1rem' }}>
                <Link href={mainPanelUrl} sx={{ color: 'white', textDecoration: 'underline' }}>
                    <Icon
                        className='fas fa-arrow-left'
                        sx={{ mr: '0.25rem', verticalAlign: 'bottom', fontSize: '1rem' }}
                    />
                    {t('panel.backToMain')}
                </Link>
            </Grid>
        </Grid>
    );

    const mainContent = (
        <>
            {closed && <AlertMessage message={t('panel.closureInfo')} type='warning' />}
            <Box sx={{ /*width: '100%', mx: '2.5rem',*/ bgcolor: 'white' }}>
                <Typography variant='h2'>
                    {title}
                </Typography>
                <Box fontSize='0.9rem'>
                    <Icon
                        className='far fa-comment'
                        sx={{ mr: '0.25rem', verticalAlign: 'bottom', fontSize: '1rem' }}
                    />
                    {comment_amount} {t('panel.comments')}
                    <Box component='span' sx={{
                        ':after': {
                            content: '""',
                            display: 'inline-block',
                            margin: '0 0.75rem',
                            width: '5px',
                            height: '5px',
                            verticalAlign: 'middle',
                            borderRadius: '50%',
                            bgcolor: '#ccc',
                        }
                    }} />
                    <Link href='#comments' sx={{ fontSize: '0.9rem' }}>
                        {t('panel.writeComment')}
                    </Link>
                </Box>
                <Divider variant='middle' sx={{ my: '1rem', bgcolor: '#eee' }} />
                <Box>
                    {abstract}
                </Box>
                {image && (
                    <ImageListItem component='div' sx={{ my: '1rem' }}>
                        <img
                            src={image}
                            alt={alt_text}
                            loading='lazy'
                        />
                        {caption &&
                            <ImageListItemBar title={caption} />}
                    </ImageListItem>
                )}
                <Box component='div'
                    dangerouslySetInnerHTML={{ __html: content }}
                />
                {commenting !== 'NONE' ?
                    <SectionComments section={sectionId} sectionApiUrl={sectionApiUrl} panelClosed={closed} />
                    :
                    <Box sx={{ mt: '2rem', p: '1.5rem 1.5rem 0.1rem', bgcolor: '#ccc' }}>
                        <AlertMessage message={t('comment.noCommenting')} type='info' />
                    </Box>
                }
            </Box>
        </>
    );

    return (
        <>
            <Hero title={heroTitle} content={heroContent} />
            <Main width='md' content={mainContent} />
        </>
    );

}

export default Section;