import { Button, Stack } from '@mui/material';

import i18n from '../i18n';
import { useLanguage } from '../context/LanguageContext';


const LanguageSwitcher = () => {
    const { language, setLanguage } = useLanguage();

    const languageList = [
        { label: i18n.t('lang.en'), code: 'en' },
        { label: i18n.t('lang.fi'), code: 'fi' },
        { label: i18n.t('lang.sv'), code: 'sv' },
    ];

    return (
        <Stack spacing={0} direction='row'>
            {languageList.map((lng) => (
                <Button
                    key={lng.label}
                    onClick={() => {
                        setLanguage(lng.code);
                        localStorage.setItem('language', lng.code);
                        i18n.changeLanguage(lng.code);
                    }}
                    disableRipple
                    sx={{
                        margin: '0',
                        padding: '0',
                        color: 'black',
                        fontSize: '0.9rem',
                        fontWeight: '400',
                        textDecoration: language === lng.code ? 'underline' : 'none',
                        '&:hover': {
                            bgcolor: 'transparent',
                            textDecoration: 'underline',
                        },
                    }}
                >
                    {lng.label}
                </Button>
            ))}
        </Stack>
    )
}

export default LanguageSwitcher;