import { Box, Container, Typography } from '@mui/material';


const Main = ({ width='lg', content }) => {
    
    return (
        <Container maxWidth='false' sx={{ bgcolor: 'investLightGrey' }}>
            <Container maxWidth={width} sx={{ p: '1rem 0 2rem', bgcolor: 'white' }}>
                <Box component='section' sx={{ m: '16px 0 0', p: '16px' }}>
                    {content}
                </Box>
            </Container>
        </Container>
    );
}
export default Main;