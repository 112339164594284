import { createContext, useContext, useState } from 'react';

import i18n from '../i18n';


const LanguageContext = createContext({
    language: i18n.language,
    setLanguage: () => {},
});

export const LanguageProvider = ({ children }) => {
    const [language, setLanguage] = useState(i18n.language);

    return (
        <LanguageContext.Provider value={{ language, setLanguage }}>
            {children}
        </LanguageContext.Provider>
    );
};

export const useLanguage = () => {
    return useContext(LanguageContext);
};