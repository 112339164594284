

export const EmailValidator = value => {
    if (!/^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]+$/.test(value))
        return "Invalid email address";
    return false;
};

export const usernameValidator = value => {
    if (!/^[\wåÅäÄöÖ-]{3,20}$/.test(value))
        return "Username must be 3-20 characters long with letters and numbers only";
    return false;
};

export const passwordValidator = value => {
    if (value.length < 8)
        return "Password must be at least 8 characters long";
    if (!/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{8,}$/.test(value))
        return "Password must contain both lowercase and uppercase letters, one number and one special character";
    return false;
};

export const loginPasswordValidator = value => {
    if (value.length < 8)
        return "Please enter password";
    return false;
};