import { useState } from 'react';

import { Box, Collapse, IconButton, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


const ExpandContent = ({ buttonText, collapseContent, openDefault }) => {
    const [expanded, setExpanded] = useState(openDefault);

    return (
        <>
            <Box display='flex' alignItems='center'>
                <IconButton
                    onClick={() => setExpanded(!expanded)}
                    aria-expanded={expanded}
                    aria-label='show more'
                    disableRipple
                    sx={{
                        width: '100%',
                        borderRadius: 0,
                        justifyContent: 'left',
                        color: 'investDarkGrey',
                        textDecoration: 'underline',
                        '&:hover': {
                            bgcolor: 'transparent',
                            color: 'investTurq',
                            textDecoration: 'none'
                        }
                    }}
                >
                    <ExpandMoreIcon
                        sx={[
                            {
                                transition: '0.2s',
                            },
                            expanded ? {
                                transform: 'none',
                            } : {
                                transform: 'rotate(-90deg)',
                            },
                        ]}
                    />
                    <Typography
                        variant='h3'
                        sx={{
                            m: 0,
                            /*fontWeight: 'bold',
                            textDecoration: 'underline',
                            '&:hover': {
                                textDecoration: 'none'
                            }*/
                        }}>
                        {buttonText}
                    </Typography>
                </IconButton>
            </Box>
            <Collapse
                in={expanded}
                timeout='auto'
                unmountOnExit
                sx={{ mt: '0.75rem', textAlign: 'left' }}
            >
                {collapseContent}
            </Collapse>
        </>
    );

}

export default ExpandContent;