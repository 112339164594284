import { useEffect } from 'react';

import { axiosPrivateInstance } from '../utils/apiConfig';
import useAuth from './useAuth';
import useRefreshToken from './useRefreshToken';


const useAxiosPrivate = () => {
    const { accessToken, setAccessToken, csrftoken, user } = useAuth();
    const refresh = useRefreshToken();

    useEffect(() => {
        const requestIntercept = axiosPrivateInstance.interceptors.request.use(
            (config) => {
                if (!config.headers["Authorization"]) {
                    config.headers['Authorization'] = `Bearer ${accessToken}`;
                    config.headers['X-CSRFToken'] = csrftoken;
                }
                return config;
            },
            (error) => Promise.reject(error)
        );

        const responseIntercept = axiosPrivateInstance.interceptors.response.use(
            response => response,
            async (error) => {
                const originalRequest = error?.config;
                if ((error?.response?.status === 403 || error?.response?.status === 401) && !originalRequest?._retry) {
                    // Refresh token logic
                    const { csrfToken: newCSRFToken, accessToken: newAccessToken } = await refresh();
                    setAccessToken(newAccessToken);
                    originalRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
                    originalRequest.headers['X-CSRFToken'] = newCSRFToken;

                    // Once the token is refreshed, replay the original request
                    originalRequest._retry = true;
                    return axiosPrivateInstance(originalRequest);
                }
                return Promise.reject(error);
            }
        )

        return () => {
            axiosPrivateInstance.interceptors.request.eject(requestIntercept);
            axiosPrivateInstance.interceptors.response.eject(responseIntercept);
        }
    }, [accessToken, setAccessToken, csrftoken, user, refresh]);

    return axiosPrivateInstance;
}

export default useAxiosPrivate;