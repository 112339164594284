import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Button, TextField } from '@mui/material';
import Grid from '@mui/material/Grid2';

import { axiosInstanceNoCredentials } from '../../utils/apiConfig';

import Hero from '../../components/layout/Hero';
import Main from '../../components/layout/Main';
import AlertMessage from '../../components/forms/AlertMessage';
import { EmailValidator } from '../../components/forms/validators';


const RequestResetPassword = () => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState(null);
    const [alertType, setAlertType] = useState('warning');
    const [formValues, setFormValues] = useState({
        email: '',
        error: false
    });

    const handleChange = (e) => {
        const email = e.target.value;
        const validator = EmailValidator;
        let error = validator(email);

        setFormValues({
            email,
            error
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (e.currentTarget.checkValidity() === false) {
            setMessage(t('form.error.form'));
            setAlertType('error');
        } else {
            setLoading(true);
            let email = formValues.email;

            try {
                const response = await axiosInstanceNoCredentials.post('auth/reset-password/', JSON.stringify({ email }));
                setLoading(false);
                setMessage('Password reset email sent. Check your inbox.');
                setAlertType('info');
            } catch (error) {
                setLoading(false);
                // TODO: handle errors
                setMessage(error.response.data.detail);
                //setMessage('Error sending password reset email.');
                setAlertType('error');
            }
        }
    }

    const heroTitle = t('form.resetTitle');
    const heroContent = '!!! NOT WORKING YET !!!';

    const mainContent = (
        <Grid container spacing={2}>
            <Grid size={{ xs: 12 }}>
                {message && <AlertMessage message={message} type={alertType} />}
            </Grid>
            <Grid size={{ xs: 12, sm: 10, md: 8 }} offset={{ xs: 0, sm: 1, md: 2 }}>
                <Box
                    component='form'
                    onSubmit={handleSubmit}
                    onChange={handleChange}
                    noValidate
                >
                    <TextField
                        name='email'
                        type='email'
                        label={t('form.email')}
                        value={formValues.email}
                        onChange={handleChange}
                        error={!!formValues.error}
                        helperText={formValues.error}
                        required
                        fullWidth
                        margin='dense'
                    />
                    <Button
                        type='submit'
                        variant='contained'
                        disabled={loading}
                        fullWidth
                        sx={{ mt: '0.5rem' }}
                    >
                        {t('form.buttonSubmit')}
                    </Button>
                </Box>
            </Grid>
        </Grid>
    );

    return (
        <>
            <Hero title={heroTitle} content={heroContent} />
            <Main width='md' content={mainContent} />
        </>
    );
}

export default RequestResetPassword;