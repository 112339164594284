import { useLocation } from 'react-router-dom';
import { ListItemButton, ListItemIcon, ListItemText } from '@mui/material';


export const DrawerNavButton = ({ action, icon, label, styles }) => {
    return (
        <ListItemButton
            //key={key}
            component='button'
            onClick={action}
            disableRipple
            sx={styles}
        >
            <ListItemIcon sx={{ color: 'inherit' }}>
                {icon}
            </ListItemIcon>
            <ListItemText
                primary={label}
                primaryTypographyProps={{ fontSize: '1rem' }}
            />
        </ListItemButton>
    );
};

export const DrawerNavLink = ({ action, icon, label, styles }) => {
    const location = useLocation();
    
    return (
        <ListItemButton
            //key={key}
            component='a'
            href={action}
            disableRipple
            sx={styles}
        >
            <ListItemIcon sx={{ color: 'inherit' }}>
                {icon}
            </ListItemIcon>
            <ListItemText
                primary={label}
                primaryTypographyProps={{
                    fontSize: '1rem',
                    fontWeight: location.pathname === action ? 'bold' : 'medium',
                }}
            />
        </ListItemButton>
    );
};