import { IconButton, Typography } from '@mui/material';


const userMenuStyles = {
    flexDirection: 'column',
    mx: '0.25rem',
    borderRadius: 0,
    color: 'investDarkGrey',
    '&:hover': {
        bgcolor: 'transparent',
        color: 'black',
    },
}

export const UserNavButton = ({ action, icon, label }) => {
    return (
        <IconButton
            onClick={action}
            size='small'
            sx={userMenuStyles}
        >
            {icon}
            <Typography variant='caption' fontSize='0.75rem'>
                {label}
            </Typography>
        </IconButton>
    );
}

export const UserNavLink = ({ action, icon, label }) => {
    return (
        <IconButton
            href={action}
            size='small'
            sx={userMenuStyles}
        >
            {icon}
            <Typography variant='caption' fontSize='0.75rem'>
                {label}
            </Typography>
        </IconButton>
    );
}