import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Button, Icon, Link, List, ListItem, Stack, TextField, Typography } from '@mui/material';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import PersonIcon from '@mui/icons-material/Person';

import { fetchData } from '../actions';
import useAuth from '../hooks/useAuth';
import AlertMessage from '../components/forms/AlertMessage';
import { useLanguage } from '../context/LanguageContext';
import { axiosInstance } from '../utils/apiConfig';
import FormatTime from '../utils/formatTime';


const SectionComments = ({ section, sectionApiUrl, panelClosed }) => {
    const { t } = useTranslation();
    const { language } = useLanguage();
    const [newCommentAdded, setNewCommentAdded] = useState(false);
    const { user, isLoggedIn } = useAuth();
    const lastComment = useRef(null);

    // fetch comments
    const [comments, setComments] = useState(null);
    const [commentAmount, setCommentAmount] = useState('0');
    const commentsApiUrl = sectionApiUrl + '/comments/';

    useEffect(() => {
        fetchData(commentsApiUrl)
            .then(data => setComments(data))
            .catch(() => { })

        setNewCommentAdded(false);
    }, [commentsApiUrl, newCommentAdded]);

    useEffect(() => {
        if (comments)
            setCommentAmount(comments.length);
    }, [comments])

    // comment form
    const commentingOpen = true;
    const [showForm, setShowForm] = useState(false);
    const [showReplyForm, setShowReplyForm] = useState(false);
    const [commentText, setCommentText] = useState('');
    const [commentError, setCommentError] = useState(false);
    const [formdata, setFormdata] = useState({
        section: section,
        author: user?.id,
        language_code: language,
        content: '',
        reply_to: ''
    });

    // comment form: on change
    const handleChange = (e) => {
        setCommentText(e.target.value);
        if (e.target.value.length < 5) {
            setCommentError(true);
        } else {
            setCommentError(false);
            setFormdata({
                ...formdata,
                author: user?.id,
                content: e.target.value,
                reply_to: ''
            });
        }
    }

    const setRefElement = (el) => {
        if (!el) return;
        lastComment.current = el;
    };

    // comment form: submit
    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!commentText) {
            setCommentError(true);
        } else {
            try {
                await axiosInstance.post(commentsApiUrl, JSON.stringify(formdata));
                setShowForm(!showForm);
                setNewCommentAdded(true);
                setCommentText('');
                setFormdata({
                    ...formdata,
                    content: '',
                    reply_to: ''
                });
                lastComment.current.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                    inline: 'start'
                });
            } catch (error) {
                // TODO: handle errors
                console.log(error.response.data);
            }
        }
    }

    // comment form: print form
    const commentForm = (cancelFunc) => (
        <Box sx={{ mb: '2rem', p: '1rem', bgcolor: 'white' }}>
            <Typography variant='h2' sx={{ mb: '1rem', px: '0.5rem', pb: '0.75rem', borderBottom: '1px solid' }}>
                {t('comment.add')}
            </Typography>
            {!isLoggedIn ? (
                <Typography sx={{ mt: '1.5rem', mb: '0.5rem', ml: '0.5rem' }}>
                    {t('comment.loginNeeded')}
                </Typography>
            ) : (
                <>
                    <Typography sx={{ mb: '1.5rem', fontSize: '1rem', px: '0.5rem' }}>
                        {t('comment.disclaimer')}
                    </Typography>
                    <Typography sx={{ mb: '0.75rem', ml: '0.5rem', fontWeight: 'bold' }}>
                        <PersonIcon
                            sx={{ verticalAlign: 'bottom', mr: '0.25rem' }}
                        />
                        {user?.username}
                    </Typography>
                    <Box component='form' onSubmit={handleSubmit} noValidate>
                        <TextField
                            required
                            label={t('comment.label')}
                            value={commentText}
                            onChange={handleChange}
                            error={commentError}
                            helperText={commentError ? t('comment.error') : ""}
                            multiline
                            rows={5}
                            fullWidth
                            margin='dense'
                        />
                        <Stack
                            spacing={1}
                            direction='row'
                            alignItems='center'
                            justifyContent='flex-end'
                            sx={{ mt: '0.75rem' }}
                        >
                            <Button onClick={cancelFunc} variant='outlined'>
                                {t('comment.cancel')}
                            </Button>
                            <Button type='submit' variant='contained'>
                                {t('comment.submit')}
                            </Button>
                        </Stack>
                    </Box>
                </>
            )}
        </Box>
    );

    const addCommentButton = (
        <Button
            size='small'
            fullWidth
            startIcon={<QuestionAnswerIcon />}
            onClick={() => setShowForm(!showForm)}
            sx={{
                mb: '2rem',
                py: '0.75rem',
                borderRadius: 0,
                bgcolor: 'black',
                color: 'white',
                fontSize: '1rem',
                '&:hover': {
                    bgcolor: 'investTurq'
                },
            }}
        >
            {t('comment.add')}
        </Button>
    );

    const replyLink = (
        <Link
            onClick={() => setShowReplyForm(!showReplyForm)}
            sx={{
                fontSize: '0.9rem',
                '&:hover': { cursor: 'pointer' }
            }}
        >
            <Icon
                className='fas fa-reply'
                sx={{
                    mr: '0.25rem',
                    verticalAlign: 'middle',
                    fontSize: '0.9rem',
                }}
            />
            {t('comment.reply')}
        </Link>
    );

    return (
        <Box sx={{ mt: '2rem', p: '1.5rem', bgcolor: '#ccc' }}>
            {commentingOpen && !panelClosed ? (
                showForm ? commentForm(() => setShowForm(!showForm)) : addCommentButton
            ) : (
                <AlertMessage message={t('comment.closed')} type='info' />
            )}
            <Typography
                variant='h2'
                sx={{
                    mb: '1.5rem',
                    px: '0.5rem',
                    pb: '0.75rem',
                    borderBottom: '1px solid'
                }}
            >
                <Stack
                    spacing={2}
                    direction='row'
                    alignItems='center'
                    justifyContent='space-between'
                >
                    <Box id='comments'>
                        {t('comment.title')}
                    </Box>
                    <Box>
                        <Icon
                            className='far fa-comment'
                            sx={{ mr: '0.5rem', verticalAlign: 'top', fontSize: 'inherit' }}
                        />
                        {commentAmount}
                    </Box>
                </Stack>
            </Typography>
            {commentAmount > 0 ? (
                <List sx={{ py: 0 }}>
                    {comments.map((comment, index) => {
                        const comment_id = 'comment-' + comment.id;
                        return (
                            <ListItem
                                id={comment_id}
                                key={index}
                                ref={ref => { index === comments.length - 1 && setRefElement(ref) }}
                                sx={{
                                    mt: '1rem',
                                    py: '1rem',
                                    flexWrap: 'wrap',
                                    gap: '0.5rem 1rem',
                                    bgcolor: 'white'
                                }}
                            >
                                <Typography fontWeight='bold'>
                                    {comment.author}
                                </Typography>
                                <Typography fontSize='0.9rem'>
                                    {FormatTime(comment.date_created, language)}
                                </Typography>
                                <Typography sx={{ flexBasis: '100%' }}>
                                    {comment.content}
                                </Typography>
                                {isLoggedIn && commentingOpen && !panelClosed &&
                                    showReplyForm ? commentForm(() => setShowReplyForm(!showReplyForm)) : replyLink}
                            </ListItem>
                        );
                    })}
                </List>
            ) : (
                <Typography sx={{ mt: '1rem', px: '0.5rem' }}>
                    {t('comment.noComments')}
                </Typography>
            )}
        </Box>
    );
}

export default SectionComments;