import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Box, Card, CardActionArea, CardContent, CardMedia, Link, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { grey } from '@mui/material/colors';
import ModeCommentOutlinedIcon from '@mui/icons-material/ModeCommentOutlined';

import { DEFAULT_LANGUAGE, NO_IMAGE } from '../../constants';
import { useLanguage } from '../../context/LanguageContext';
import { getSectionURL } from '../../actions';


const SectionList = ({ panelSlug, sections }) => {
    const { t } = useTranslation();
    const { language } = useLanguage();

    return (
        <>
            <Grid size={12}>
                <Typography variant='h3'>
                    {t('panel.titleSections')}
                </Typography>
            </Grid >
            {sections.map((section, index) => {
                const imageUrl = section.image ? section.image : NO_IMAGE;
                const sectionUrl = getSectionURL(panelSlug, section.id);
                const sectionTitle = section.title[language] ? section.title[language] : section.title[DEFAULT_LANGUAGE];
                const sectionNumber = parseInt(index) + 1 + '/' + sections.length;

                return (
                    <Grid size={{ xs: 12, md: 6 }} key={index}>
                        <Card square elevation={0} sx={{ position: 'relative', maxWidth: '100%', height: '100%', bgcolor: grey[100] }}>
                            <CardActionArea component={Link} href={sectionUrl}>
                                <CardMedia
                                    component='img'
                                    image={imageUrl}
                                    alt={sectionTitle}
                                    sx={{ height: '220px' }}
                                />
                            </CardActionArea>
                            <CardContent sx={{ padding: '1.5rem' }}>
                                <Typography component='div' sx={{ mb: '0.25rem', fontSize: '0.9rem' }}>
                                    {t('panel.section')} {sectionNumber}
                                </Typography>
                                <CardActionArea component={Link} href={sectionUrl}>
                                    <Typography gutterBottom variant='h4' sx={{ mb: '0.5rem', fontWeight: 'bold' }}>
                                        {sectionTitle}
                                    </Typography>
                                </CardActionArea>
                            </CardContent>
                            <Box sx={{
                                position: 'absolute',
                                top: 0,
                                right: 0,
                                p: '0.5rem 1rem',
                                borderRadius: '0 0 0 1rem',
                                bgcolor: 'investGreen',
                                color: 'white',
                                fontSize: '1rem'
                            }}>
                                <ModeCommentOutlinedIcon
                                    sx={{ mr: '0.5rem', verticalAlign: 'middle', fontSize: '1rem' }}
                                />
                                {section.n_comments}
                            </Box>
                        </Card>
                    </Grid>
                );
            })}
        </>
    );

}

export default SectionList;