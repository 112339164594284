import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Icon, Link, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';

import { axiosInstanceNoCredentials } from '../../utils/apiConfig';

import Hero from '../../components/layout/Hero';
import Main from '../../components/layout/Main';
import AlertMessage from '../../components/forms/AlertMessage';


const ActivateUser = () => {
    const { t } = useTranslation();
    const { token } = useParams();

    const [message, setMessage] = useState(null);
    const [alertType, setAlertType] = useState('warning');
    const TOKEN_URL = 'auth/activate/' + token + '/'

    useEffect(() => {
        const verifyEmail = async () => {
            try {
                await axiosInstanceNoCredentials.get(TOKEN_URL);
                setMessage('Email verified successfully!');
                setAlertType('success');
            } catch (err) {
                setMessage('Failed to verify email. Please try again.');
                console.error('Failed:', err);
            }
        };

        if (token) {
            verifyEmail();
        } else {
            setMessage('Email verification token not found!');
            console.error('No token:');
        }
    }, [token, TOKEN_URL]);

    const heroTitle = t('form.activationTitle');
    const heroContent = '';

    const mainContent = (
        <Grid container spacing={2}>
            <Grid size={{ xs: 12 }}>
                {message && <AlertMessage message={message} type={alertType} />}
            </Grid>
            <Grid size={{ xs: 12 }}>
                <Typography variant='h3' sx={{ m: 0, textAlign: 'center' }}>
                    <Link
                        href='/auth/login'
                        fullWidth
                        sx={{ fontSize: 'inherit' }}
                    >
                        {t('form.logInNow')}
                        <Icon
                            className='fas fa-angles-right'
                            sx={{ verticalAlign: 'middle' }}
                        />
                    </Link>
                </Typography>
            </Grid>
        </Grid>
    );

    return (
        <>
            <Hero title={heroTitle} content={heroContent} />
            <Main width='md' content={mainContent} />
        </>
    );
}

export default ActivateUser;