import { Box, Container, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';


const Hero = ({ title, content }) => {
    const containerClasses = {
        padding: '1.5rem 0',
        bgcolor: 'investBlue',
        borderBottom: '10px solid black',
    };
    const typographyClasses = {
        marginBottom: '1rem',
        textAlign: 'center',
        color: 'white',
    }

    return (
        <Container maxWidth='false' sx={containerClasses}>
            <Container maxWidth='lg'>
                <Box component='section' sx={{ margin: '16px 0 0', padding: '16px' }}>
                    <Grid container spacing={2}>
                        <Grid size={{ xs: 12, sm: 12 }}>
                            <Typography variant='h1' fontSize={{ xs: '2.5rem', md: '3.5rem', lg: '4.5rem' }} sx={typographyClasses}>
                                {title}
                            </Typography>
                        </Grid>
                        <Grid size={{ xs: 12, sm: 12 }}>
                            <Typography variant='herotext' fontSize={{ xs: '1.4rem', lg: '1.5rem' }} sx={typographyClasses}>
                                {content}
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </Container>
    );
}

export default Hero;