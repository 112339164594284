import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Box, Container, Divider, Link, List, ListItemButton, ListItemText, ListSubheader, Stack, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import LaunchIcon from '@mui/icons-material/Launch';

import { LOGO_FOOTER_EN, LOGO_FOOTER_FI } from '../../constants';
import { useLanguage } from '../../context/LanguageContext';


const Footer = () => {
    const { t } = useTranslation();
    const { language } = useLanguage();
    const location = useLocation();
    const footerLink = 'https://' + t('footer.link');

    const navItems = [
        { label: t('nav.homeLinkText'), path: "/" },
        { label: t('nav.panelsLinkText'), path: "/panels/" },
        { label: t('nav.infopageLinkText'), path: "/info/" },
    ];
    const linkItems = [
        { label: t('nav.accessibilityLinkText'), path: '/accessibility/', icon: false },
        { label: t('nav.privacyLinkText'), path: '/privacy-policy/', icon: false },
        { label: t('nav.feedbackLinkText'), path: 'mailto:innext@utu.fi', icon: true },
    ];

    const defaultLinkStyles = {
        color: 'white',
        textDecoration: 'underline',
        '&:hover': {
            color: 'investLightGrey',
            textDecoration: 'none',
        }
    };
    const footerLinkStyles = {
        fontSize: { sm: '1rem', md: '0.9rem' }
    };
    const footerTextStyles = {
        display: { xs: 'block', md: 'inline' },
        pt: { xs: '1.5rem', md: '0' },
    };
    const listItemTextProps = (path) => ({
        fontSize: '1rem',
        fontWeight: location.pathname === path ? 'bold' : 'medium',
    });

    const logoLink = (
        <>
            <Box
                component='img'
                src={language === 'fi' ? LOGO_FOOTER_FI : LOGO_FOOTER_EN}
                alt={t('logoAlt')}
                sx={{
                    display: 'block',
                    maxWidth: '13rem',
                    mb: '1.5rem',
                }}
            />
            <Box
                component={Link}
                href={footerLink}
                sx={Object.assign({}, defaultLinkStyles, { pl: '4rem' })}
            >
                {t('footer.link')}
            </Box>
        </>
    );

    const footerMenu = (
        <List
            component='div'
            dense
            disablePadding
            subheader={<ListSubheader
                component='h4'
                color='inherit'
                disableSticky
                sx={{
                    m: { xs: '1.5rem 0 0', sm: '1.25rem 0 0.5rem' },
                    fontSize: '1.2rem',
                    fontWeight: 'bold'
                }}
            >
                {t('appName')}
            </ListSubheader>}
        >
            {navItems.map((item) => (
                <ListItemButton
                    key={item.path}
                    component='a'
                    href={item.path}
                    disableRipple
                    sx={defaultLinkStyles}
                >
                    <ListItemText
                        primary={item.label}
                        primaryTypographyProps={listItemTextProps(item.path)}
                        sx={{ my: 0 }}
                    />
                </ListItemButton>
            ))}
        </List>
    );

    const footerLinks = (
        <Stack
            direction={{ sm: 'column', md: 'row' }}
            spacing={{ xs: 1, md: 2 }}
            divider={<Divider
                orientation='vertical'
                flexItem
                sx={{
                    display: { xs: 'none', md: 'block' },
                    bgcolor: 'investDarkGrey'
                }}
            />}
            sx={{
                p: { xs: '0 1rem', sm: '0 1rem' },
                justifyContent: 'flex-end'
            }}
        >
            {linkItems.map((item) => (
                <Box
                    key={item.path}
                    component={Link}
                    href={item.path}
                    sx={Object.assign({}, defaultLinkStyles, footerLinkStyles)}
                >
                    {item.label}
                    {item.icon === true &&
                        <LaunchIcon fontSize='small' sx={{
                            width: '0.6em',
                            height: '0.6em',
                            marginLeft: '0.25rem'
                        }} />
                    }
                </Box>
            ))}
            <Typography
                variant='div'
                sx={Object.assign({}, footerTextStyles, footerLinkStyles)}
            >
                &copy; {new Date().getFullYear()} {t('projectName')}
            </Typography>
        </Stack>
    );

    return (
        <Container maxWidth='false' sx={{ padding: { xs: '1rem 0 2rem', md: '2rem 0 3rem' } }}>
            <Container maxWidth='lg'>
                <Box sx={{ margin: '16px 0 0', padding: '16px' }}>
                    <Grid container spacing={{ xs: 0, sm: 2 }}>
                        <Grid size={{ xs: 12, sm: 6, md: 4 }}>
                            {logoLink}
                        </Grid>
                        <Grid size={{ xs: 12, sm: 6, md: 4 }}>
                            {footerMenu}
                        </Grid>
                        <Grid size={12} sx={{ mt: { xs: 0, sm: '1rem', md: 0 } }}>
                            {footerLinks}
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </Container>
    );
}

export default Footer;